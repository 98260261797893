
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}
.container{
    width: 100vw;
    height: 100vh;
    background-color: black;
    background-image: url(../assets/loginbg.png)  ;
    /* /* background-position: center center; */
    /* background-repeat: repeat-x; */
    /* background-size: 100vw; */
}
.box{
    width: 90%;
    max-width: 350px;
    text-align: center;
    top: 50%;
    left: 50%;
    position: absolute;
    background-color: rgb(255, 255, 255,0.92);
    transform: translate(-50%,-50%);
    padding: 50px 60px ;
}
.box h1{
    font-size: 30px;
    margin-bottom: 50px;
    color: rgb(8, 22, 113);
}
.inputs{
    height: 250px;
}
.input{
    background-color: rgba(210, 213, 215);
    border-radius: 3px;
    display: flex;
    margin: 15px 0;
    align-items: center;
    overflow: hidden;
    max-height: 100px;

    
}
.login-input{
    width:100%;
    border: 0;
    padding: 18px 15px;
    background: transparent;

}
.buttons{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.login-button{
    flex-basis: 40%;
    border: 1px solid rgb(8, 22, 113);
    background-color:rgb(8, 22, 113) ;
    height: 40px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
}
.buttons .login-button.disable{
    background-color: rgb(140, 140, 140);
    color: rgb(214, 215, 216);
    border: 1px solid  rgb(140, 140, 140);
}