/* Navbar CSS */
.navbar {
    background: linear-gradient(to right, #4d9de0, #5a87d7); /* Updated gradient background */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    font-size: 24px;
    font-weight: bold;
    color: #fff; /* White text color */
    text-decoration: none;
    position: relative;
}

.navbar-user {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 20px; /* Increased right margin */
    justify-content: space-between;
}

.navbar-user img {
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.navbar-username {
    font-weight: bold;
    color: #fff; /* White text color */
}

.navbar-logout-icon {
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
    color: #fff; /* White icon color */
}
